$secondary: #404447;
$font-family-sans-serif: "Glory";

@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Glory:wght@400&display=swap');

body {
    background-color: rgba(0, 0, 0, 0.9);
}

#sig:hover {
    filter:
        invert(0.4) sepia(1) hue-rotate(4deg) saturate(14.5) brightness(1.2);
}

#sig:active {
    filter:
        invert(0) sepia(0) hue-rotate(0deg) saturate(0) brightness(0);
}

.nav-link {
    color: $warning !important;
}

.btn:hover {
    color: $dark !important;
}

.btn:focus {
    outline: none;
    box-shadow: none;
}

form *:focus {
    outline-color: $light !important;
    box-shadow: 0px 0px 5px 2px $light !important;
}

.nav-link.btn:focus {
    color: $dark !important;
    background-color: $warning;
}

.modal-content {
    background-color: $dark;
    color: $light;
}

.side-nav-link {
    position: fixed;
    left: -180px;
    /* Position them outside of the screen */
    transition: 0.3s;
    /* Add transition on hover */
    width: 230px;
    /* Set a specific width */
    text-decoration: none;
    /* Remove underline */
    color: white;
    /* White text color */
    border-radius: 0 5px 5px 0;
    /* Rounded corners on the top right and bottom right side */
    border: 4px solid $dark;
    z-index: 99;
}

.side-nav-link:hover {
    left: -10px;
    /* On mouse-over, make the elements appear as they should */
    color: white;
    /* White text color */
}

.bi-caret-right-fill {
    position: absolute;
    right: -14px;
    background-color: $dark;
    border-radius: 10px;
}

#software-link {
    top: 180px;
    background-color: $secondary;
}

#music-link {
    top: 270px;
    background-color: $secondary;
}

.vw-25 {
    width: 25vw !important;
}

.vw-50 {
    width: 50vw !important;
}

.vw-75 {
    width: 75vw !important;
}

.vh-25 {
    height: 25vh !important;
}

.vh-50 {
    height: 50vh !important;
}

.vh-75 {
    height: 75vh !important;
}

.project-card,
.blogpost-card {
    background-color: $gray-300;
}

.project-card:hover,
.blogpost-card:hover {
    background-color: $light;
}

.img-hover-zoom {
    max-height: 220px;
    overflow: hidden;
}

.img-hover-zoom p {
    transition: 0.3s;
    filter: blur(2px);
    z-index: 0;
}

.project-card:active,
.blogpost-card:active {
    filter: drop-shadow(0px 0px 8px $light);
    // filter: brightness(90%);
}

.img-hover-zoom img,
.img-hover-zoom img p {
    transition: 0.3s;
    transform: scale(1);
}

.project-card:hover img,
.blogpost-card:hover img {
    transform: scale(1.3);
}

.custom-scrollbar-css {
    height: 200px;
}

.overflow-y-hidden {
    overflow-y: hidden !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

/* Custom Scrollbar */
.custom-scrollbar {
    overflow-y: scroll;
}

@media (min-width: 992px) {
    p.custom-scrollbar {
        max-height: 40vh;
    }

    p.custom-scrollbar.blog-post {
        max-height: 55vh;
    }
}

.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: $secondary;
    border-radius: 1rem;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: $dark;
    background-image: linear-gradient(to top, #8b5a00 0%, $warning 100%);
}

#resume ul {
    margin: 0;
}

.img-thumbnail {
    max-width: 500px !important;
    max-height: 500px !important;
}

footer a {
    color: white !important;
}

footer .btn:hover {
    color: $warning !important;
}